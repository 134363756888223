import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";

import "@/permission";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

import "@/style/index.scss";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

Vue.config.productionTip = false;
Vue.prototype.$store = store;
new Vue({
  store,
  router,

  render: (h) => h(App),
}).$mount("#app");
