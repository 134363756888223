import axios from "axios";
import { Message } from "element-ui";

import auth, { SET_ADMIN_TOKEN, DEL_ADMIN_TOKEN } from "@/utils/auth.js";

const services = axios.create({
  baseURL: "https://haohaochifan.lijingjuan.cn",
  // baseURL: "http://43.143.169.24:801/",
  // baseURL: "/api",
  timeout: 16000,
});

let isMobile;
if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) {
  isMobile = true;
} else {
  isMobile = false;
}
//请求拦截
services.interceptors.request.use(
  (config) => {
    if (auth.GET_ADMIN_TOKEN() && auth.GET_ADMIN_TOKEN() != "undefined") {
      config.headers["Authorization"] = "bearer " + auth.GET_ADMIN_TOKEN();
    }
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    return error;
  }
);

//响应拦截
services.interceptors.response.use(
  (response) => {
    const res = response.data;

    if (res.code == 200) {
      return res;
    } else if (res.code == 401 && res.msg == "身份令牌无效") {
      Message({
        showClose: true,
        message: res.msg,
        type: "error",
        duration: 5000,
      });
      DEL_ADMIN_TOKEN();
      location.reload();
    } else {
      if (!isMobile) {
        Message({
          showClose: true,
          message: res.msg,
          type: "error",
          duration: 5000,
        });
      }
      return res;
    }
  },
  (error) => {
    const err = error.response.data;
    if (!isMobile) {
      Message({
        showClose: true,
        message: err.msg,
        type: "error",
        duration: 5000,
      });
      return Promise.reject(err);
    } else {
      // Toast.fail("");
      return Promise.reject(err);
    }
  }
);

export function login(params) {
  // console.log(params);
  // var formData = new FormData();
  // formData.append({ ...params });

  return services({
    url: "/user/login",
    method: "post",

    data: params,
  });
}
export function getUserinfo(params) {
  return services({
    url: "/user/getuserinfo",
    method: "get",
    params,
  });
}
export function updateAvatar(params) {
  return services({
    url: "/user/updateAvatar",
    method: "post",
    data: params,
  });
}
export function userList(params) {
  return services({
    url: "/user/List",
    method: "get",
    params,
  });
}
export function addMenu(params) {
  return services({
    url: "/menus/Add",
    method: "post",
    data: params,
  });
}
export function MenuList(params) {
  return services({
    url: "/menus/List",
    method: "get",
    params,
  });
}
export function typeList(params) {
  return services({
    url: "/menus/typeList",
    method: "get",
    params,
  });
}
export function delType(params) {
  return services({
    url: "/menus/delType",
    method: "post",
    data: params,
  });
}
export function delMenu(params) {
  return services({
    url: "/menus/delMenu",
    method: "post",
    data: params,
  });
}
export function addType(params) {
  return services({
    url: "/menus/addType",
    method: "post",
    data: params,
  });
}
export function addMenus(params) {
  return services({
    url: "/menus/addMenus",
    method: "post",
    data: params,
  });
}
export function editMenu(params) {
  return services({
    url: "/menus/editMenu",
    method: "post",
    data: params,
  });
}
export function checkRole(params) {
  return services({
    url: "/user/check",
    method: "get",
    params,
  });
}
