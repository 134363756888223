const ADMIN_TOKEN = "ADMIN_TOKEN";
const ADMIN_USERINFO = "ADMIN_USERINFO";

export function SET_ADMIN_TOKEN(data) {
  return window.sessionStorage.setItem(ADMIN_TOKEN, data);
}
export function DEL_ADMIN_TOKEN() {
  return window.sessionStorage.removeItem(ADMIN_TOKEN);
}
export function SET_ADMIN_USERINFO(data) {
  return window.sessionStorage.setItem(ADMIN_USERINFO, JSON.stringify(data));
}
export function DEL_ADMIN_USERINFO() {
  return window.sessionStorage.removeItem(ADMIN_USERINFO);
}

export default {
  GET_ADMIN_TOKEN() {
    return window.sessionStorage.getItem(ADMIN_TOKEN);
  },

  GET_ADMIN_USERINFO() {
    return JSON.parse(window.sessionStorage.getItem(ADMIN_USERINFO));
  },
};
