import setName from "@/utils/setPage";
import router from "./router";
import auth from "@/utils/auth";
let isMobile;
if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) {
  isMobile = true;
} else {
  isMobile = false;
}
let WHITELIST = ["/login", "/404"];
router.beforeEach((to, from, next) => {
  document.title = setName(to.meta.title);
  if (auth.GET_ADMIN_TOKEN() ) {
    if (WHITELIST.includes(to.path)) {
      next("/");
    } else {
      next();
    }
  } else {
    console.log(to.path);
    

      if (to.path == "/login") {
        next();
      } else {
        next("/login");
      }
   
  }
});

router.afterEach(() => {
  // finish progress bar
});
