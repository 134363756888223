import Vuex from "vuex";
import Vue from "vue";
import { SET_ADMIN_USERINFO } from "@/utils/auth";

Vue.use(Vuex);
import { getUserinfo } from "@/api";

export default new Vuex.Store({
  state() {
    return {
      userinfor: {},
      name: "坤坤",
    };
  },

  mutations: {
    SET_USERINFO: (state, userinfo) => {
      state.userinfor = userinfo;
    },
  },

  actions: {
    GET_USERINFO({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserinfo().then((res) => {
          SET_ADMIN_USERINFO(res.data);

          resolve(res);
        });
      });
    },
  },
});
