<template>
  <div
    style="
      background-color: #f4f6fe;
      height: 100%;
      display: flex;
      flex-direction: column;
    "
  >
    <div class="top">
      <div class="isleft">
        <img
          style="
            margin-left: 16px;
            margin-right: 8px;
            width: 32px;
            height: 32px;
          "
          src="@/assets/logo.png"
          alt=""
        />
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          "
          @click="authToken"
        >
          噜噜管理后台
        </p>
      </div>
      <el-dropdown trigger="click" placement="bottom-start" @command="logout">
        <div class="isright">
          <img
            style="border-radius: 50%; width: 32px; height: 32px"
            :src="tx"
            alt=""
          />

          <p style="margin-left: 8px">{{ name }}</p>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="editTx">更换头像</el-dropdown-item>
          <el-dropdown-item command="layout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="content">
      <div class="isLeft">
        <el-menu
          ref="menu"
          :default-active="activeMenu"
          class="el-menu-vertical-demo"
          :unique-opened="true"
          @select="selectMenu"
        >
          <div v-for="(item, indexs) of routes" :key="indexs">
            <div v-if="!item.hidden">
              <el-submenu
                class="subsub"
                v-if="item.children.length != 0 && !item.children[0].meta.alone"
                :index="String(item.path)"
              >
                <template slot="title">
                  <i :class="item.meta.icon"></i>
                  <span>{{ item.meta.title }} </span>
                </template>

                <el-menu-item-group v-if="item.children.length != 0">
                  <el-menu-item
                    @click="goDetail(element.path)"
                    v-for="(element, index) of item.children"
                    :key="element.id"
                    :index="element.path"
                  >
                    <i :class="element.meta.icon"></i>

                    {{ element.meta.title }}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-menu-item
                class="subsubItem"
                v-else
                @click="goDetail(item.path)"
                :index="item.path"
              >
                <div style="display: flex; align-items: center">
                  <!-- <img
                    style="height: 14px; width: 14px; margin-right: 16px"
                    :src="icon(item.path)"
                    alt=""
                  /> -->
                  <i :class="item.meta.icon"></i>

                  {{ item.meta.title }}
                </div>
              </el-menu-item>
            </div>
          </div>
        </el-menu>
      </div>
      <div class="isRight">
        <router-view />
      </div>
    </div>
    <el-dialog
      title="修改头像"
      :visible.sync="txBoxVisible"
      width="30%"
      :before-close="handleCloseTx"
    >
      <el-upload
        v-loading="upLoading"
        class="avatar-uploader"
        action="/user/upload"
        :headers="{
          Authorization: 'bearer ' + token,
        }"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import auth, { DEL_ADMIN_TOKEN, SET_ADMIN_USERINFO } from "@/utils/auth";
import { updateAvatar, getUserinfo } from "@/api";
export default {
  data() {
    return {
      upLoading: false,
      token: auth.GET_ADMIN_TOKEN(),
      name: "",
      activeMenu: "",
      txBoxVisible: false,
      imageUrl: "",
      userinfo: auth.GET_ADMIN_USERINFO() || {},
      tx: auth.GET_ADMIN_USERINFO().avatar || require("@/assets/logo.png"),
    };
  },
  methods: {
    authToken() {
      getUserinfo().then((res) => {});
    },
    beforeAvatarUpload(file) {
      this.upLoading = true;
    },
    handleAvatarSuccess(res) {
      if (res.msg == "身份令牌已失效") {
        this.$message.error("身份令牌已失效");
        DEL_ADMIN_TOKEN();
        location.reload();
        return;
      }
      updateAvatar({
        avatar: res.data.src,
      }).then((res2) => {
        if (res2.code == 200) {
          this.upLoading = false;
          this.imageUrl = res.data.src;
          this.$message.success("修改成功");
          this.$store.dispatch("GET_USERINFO").then((res) => {
            this.$router.go(0);
          });
          // this.txBoxVisible = false;
        }
      });
    },

    handleCloseTx() {
      this.txBoxVisible = false;
    },
    ll(index, path) {
      this.activeMenu = "/adminUser";
    },
    selectMenu() {},
    logout(val) {
      if (val == "layout") {
        DEL_ADMIN_TOKEN();
        this.$message.success("退出成功");
        setTimeout(() => {
          this.$router.push("/login");
        }, 300);
      } else if (val == "editTx") {
        this.txBoxVisible = true;
      }
    },
    goDetail(url) {
      this.$router.push(url);
    },
  },
  created() {
    this.name = this.$store.state.name;
    const route = this.$route;
    const { meta, path, matched } = route;
    if (path.includes("/dashboard")) {
      this.activeMenu = "/dashboard";
    }
    this.$nextTick(() => {
      if (this.$refs.menu.submenus.hasOwnProperty(matched[0].path)) {
        this.$refs.menu.open(matched[0].path);
        this.$refs.menu.activeIndex = matched[1].path;
      } else {
        this.$refs.menu.activeIndex = matched[0].path;
      }
    });
  },
  mounted() {},
  computed: {
    routes() {
      return this.$router.options.routes;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: $kunkun;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    &:hover {
      color: $kunkun;
    }
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .subsub {
    padding: 10px 0;

    & > ul > li > ul > li {
      text-align: left;
      padding-left: 40px !important;
      margin-top: 10px;
    }
    .el-submenu__title {
      & > i {
        color: unset;
      }
      &::hover {
        // background-color: $kunkun;
        background-color: $kunkun;
        color: #fff;
      }
      .is-active {
        & > i {
          color: unset;
        }
        &::hover {
          background-color: $kunkun;
          color: #fff;
        }
        &::before {
          content: "";
          height: 100%;
          width: 4px;
          background-color: $kunkun;
          border-radius: 2px;
          position: absolute;
          left: -8px;
        }
      }
    }
  }
  .subsubItem {
    margin-top: 10px;
    & > i {
      color: unset;
    }
    &::hover {
      background-color: $kunkun;
      color: #fff;
    }
  }
  .subsubItem.is-active {
    background-color: $kunkun;
    color: #fff;

    &::hover {
      background-color: $kunkun;
      color: #fff;
    }
  }
  .el-menu-item-group {
    .el-menu-item-group__title {
      padding: 0;
    }
    & > ul > .el-menu-item > i {
      color: unset !important;
    }
    &::hover {
      background-color: $kunkun;
      color: #fff;
    }
  }
  .el-menu-item {
    & > div > i {
      color: unset !important;
    }
    &::hover {
      background-color: $kunkun;
      color: #fff;
    }
  }
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 8px 11px 0px rgba(51, 51, 51, 0.06);
  .isleft {
    display: flex;
    align-items: center;
  }
  .isright {
    cursor: pointer;
    width: auto;
    padding-right: 80px;
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
  }
}
.content {
  flex: 1;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: space-between;
  .isLeft {
    width: 200px;
    height: calc(89vh);
    background: #ffffff;
    border-radius: 12px;
    margin: 18px 18px 18px;
  }
  .isRight {
    height: calc(100% - 18px);
    flex: 1;
    // background: #ffffff;
    border-radius: 8px;
    padding-top: 18px;
    margin-right: 18px;
  }
}
</style>