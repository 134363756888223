import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
Vue.use(VueRouter);


let isMobile;
if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) {
  isMobile = true;
} else {
  isMobile = false;
}

export const routes = [
  {
    name: "login",
    path: "/login",
    meta: {
      title: "登录",
    },
    hidden: true,
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
    hidden: true,
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: {
      title: "主页",
      icon: "el-icon-s-home",
    },
    redirect: "/home",
    component: Layout,
    children: [
      {
        name: "home",
        path: "/home",
        meta: {
          title: "仪表盘",
          icon: "el-icon-s-home",
          alone: true,
        },
        component: () => import("@/views/dashboard"),
      },
    ],
  },
  {
    name: "404",
    path: "/404",
    meta: {
      title: "404",
    },
    hidden: true,
    component: () => import("@/views/404"),
  },
  {
    name: "Menus",
    path: "/Menus",
    meta: {
      title: "菜品管理",
      icon: "el-icon-tableware",
    },
    redirect: "/Menus/index",
    component: Layout,
    children: [
      {
        name: "MenusIndex",
        path: "/Menus/index",
        meta: {
          title: "用户设置",
          alone: true,
          icon: "el-icon-s-custom",
        },
        component: () => import("@/views/menu/index"),
      },
    ],
  },
  {
    name: "system",
    path: "/system",
    meta: {
      title: "系统管理",
      icon: "el-icon-s-tools",
    },
    redirect: "/adminUser",
    component: Layout,
    children: [
      {
        name: "adminUser",
        path: "/adminUser",
        meta: {
          title: "用户设置",
          alone: false,
          icon: "el-icon-s-custom",
        },
        component: () => import("@/views/system/adminUser"),
      },
      {
        name: "setting",
        path: "/system/setting",
        meta: {
          title: "系统设置",
          alone: false,
          icon: "el-icon-set-up",
        },
        component: () => import("@/views/system/setting"),
      },
    ],
  },
];

export const mobileRoutes = [
  {
    name: "login",
    path: "/login",
    meta: {
      title: "登录",
    },
    hidden: true,
    component: () => import("@/mobile/login/login.vue"),
  },
  {
    name: "home",
    path: "/home",
    meta: {
      title: "upup",
    },
    hidden: true,
    component: () => import("@/mobile/home/index.vue"),
  },
];

export const asyncRoutes = [
  {
    name: "system",
    path: "/system",
    meta: {
      title: "系统设置",
      icon: "el-icon-s-tools",
    },
    redirect: "adminUser",
    component: Layout,
    children: [
      {
        name: "adminUser",
        path: "adminUser",
        meta: {
          title: "主页",
        },
        component: () => import("@/views/system/adminUser"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: isMobile?mobileRoutes:routes,
});

export default router;
